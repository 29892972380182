<template>
  <body>
    <div class="about">
      <h1 class="main-text">Who is Grif?</h1>
    </div>
    <div class="info">
      <button class="tag1">💻 Developer</button>
      <button class="tag2">☕ Coffee Lover</button>
      <button @click="gotosite()" class="tag3">🔨 Moderator</button>
      <button class="tag4">📚 Student</button>
      <p class="desc">
        I'm a high school student who is very interested in programming, martial
        arts, moderation and more!
        <br />
        I discovered my love for programming only a couple of years ago, and
        have been working on projects ever since. <br />
        Before I started programming, I ran and moderated communities on Discord
        which I still enjoy doing. <br />
        Apart from that, I really <span class="bol"> LOVE </span> coffee. A bit
        too much some might say. I also love and practice martial arts.
      </p>
    </div>
    <button @click="$router.push('/')" class="btn">Go Home</button>
    <button @click="$router.push('/about/contact')" class="btn">
      Contact Me
    </button>
  </body>
</template>

<script>
export default {
  name: "About",
  methods: {
    gotosite() {
      window.open("https://bit.ly/359rr5V");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");
.main-text {
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 50px;
}

body {
  overflow: hidden;
}

.desc {
  color: rgb(255, 255, 255);
  font-family: "Open Sans", sans-serif;
  font-size: 115%;
}

.tag1 {
  display: inline-block;
  background: rgb(248, 23, 98);
  color: rgb(255, 255, 255);
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.tag1:hover {
  cursor: default;
}

.tag2 {
  display: inline-block;
  background: rgb(11, 136, 253);
  color: rgb(255, 255, 255);
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.tag2:hover {
  cursor: default;
}

.tag3 {
  display: inline-block;
  background: rgb(195, 45, 255);
  color: rgb(255, 255, 255);
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.tag4 {
  display: inline-block;
  background: rgb(247, 36, 194);
  color: rgb(255, 255, 255);
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.tag4:hover {
  cursor: default;
}

.bol {
  font-weight: bold;
  color: rgb(240, 29, 29);
}
</style>
